//Global variables
:root {
  //Spacing
  --pbpadding: 3em;
}

$font-family: 'poppins-reg', sans-serif;
$heading: 'poppins-semibold', sans-serif;
$font-med: 'poppins-medium', sans-serif;
$font-light: 'poppins-light', sans-serif;
$font-extralight: 'poppins-extralt', sans-serif;
$font-clr: #000000;
$zovyellow: #F8AC16;
$zovpink: #E60B71;
$zovpurple: #755193;
$zovblue: #452775;
$zovsky: #5DABBF;
$zovgrey: #666;
$zovgreymid: #B9B9B9;
$zovgreylt: #E5E5E5;
$black: #000;
$white: #FFF;

//Legacy colours
$blue: #00A0D6;
$blue-alt: #00A6DD;
$primary: #00A0D6;
$navy: #003458;

// BOOTSTRAP GRID
$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1350px,
  xl: 1500px
);

$container-max-widths: (
  sm: 540px,
  md: 1100px,
  lg: 1296px,
  xl: 1394px
);
