//******************************************************************************
// BASE
// html, body & default styles
//******************************************************************************
:root {
    --base-font-size: 20px;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    color: $font-clr;
    font-size: var(--base-font-size);
    font-family: $font-family, Arial;
    font-variation-settings: 'wght' 400;
    background-color: white;
    position: relative;
}

p {
    line-height: 1.75;
    margin-bottom: 1.25em;
}

strong,
b {
    font-variation-settings: 'wght' 600;
}

img {
    height: auto;
}

h2 + p,
h3 + p,
h4 + p,
h5 + p,
h5 + ul {
    margin-top: 1rem;
}

ul, ol {

}

p + ul {
    margin-bottom: 2em;
}

a:not(.nav-link, .skiplink, .reset-link), .link {
    color: $black;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

article.text-side, article {
    a {
        text-underline-offset: 0.3em;
    }
}

.reset-link {
    text-decoration: none !important;
    color: black !important;
    
    &:hover {
        text-decoration: underline !important;
        text-underline-offset: 3px;
    }
}

:is(.backto.backto) {
    text-decoration: none;
    opacity: 0.6;
    margin-top: 2rem;
    display: inline-block;

    img {
        width: 27px;
        margin-right: 5px;
    }

    &:hover {
        text-decoration: underline;
    }
}

// FLEX
// -----------------------------------------------------------------------------
.mysuperflexteststyle {
    background-color: red;
}
.row-reverse {
}
.flex-end {
    display: flex;
    justify-content: flex-end;
}

// FOCUS-VISIBLE
// -----------------------------------------------------------------------------
*:focus:not(:focus-visible) {
    outline: none !important;
}

html[data-useragent*="rv:11.0"] {
    
}

// OBJECTFIT
// -----------------------------------------------------------------------------
div {
    &.objectfit {
        height: 100%;
        img {
            -o-object-fit: cover;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}

svg.icon {
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    fill: currentColor;
    position: relative;
    top: 3px;
    margin: -2px 5px 0 0;
}

.skiplink {
    position: absolute;
    left: -9999em;
    &:focus {
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        font-size: 2rem;
        z-index: 1000;
        background: #000000;
        padding: 1rem 1.8rem;
        border: 4px solid white;
    }
}

.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: auto;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

// Universal styles
// -----------------------------------------------------------------------------
.centred {
    text-align: center;
}

.align-right {
    text-align: right;
}

.gorel {
    position: relative;
}

.rounded-box {
    border-radius: 69px;
    overflow: hidden;
    @extend .trans !optional;
    &__square-bottom-left {
        border-radius: 69px 69px 69px 0;
    }
}

.bg-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.bg-blue {
    background-color: $zovblue;
    color: $white;
}
.bg-grey {
    background-color: $zovgrey;
}
.bg-greylt {
    background-color: $zovgreylt;
}
.bg-sky {
    background-color: $zovsky;
}
.gowhite {
    color: $white;
}
.goblue {
    color: $zovblue;
}
.bordered-blue {
    border: 0.5px solid $zovblue;
}
.nopadding-top {
    padding-top: 0 !important;
}
.nopadding-bottom {
    padding-bottom: 0 !important;
}
