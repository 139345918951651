@import '../abstracts/variables.scss';
@import '../abstracts/mixins.scss';
@import '../sections/base.scss';

.pb-separator {
    text-align: center;
    img {
        &.page-separator {
            width: 500px;
            max-width: 80%;
            display: inline-block;
        }
    }
}